<template>
    <div>
      <div id="rivat02">loading...</div>
      <div class="left-body">
        <b-button
            class="print ml-2"
            variant="primary"
            @click="generateXLSX()"
            >
            Xuất Excel
        </b-button>
      </div>
    </div>
  </template>
  
  <script>
  import { ROOT, REPORT_URL } from "@/api/constant";
  import { commonServices } from '@/api/common-services'
  import jQuery from "jquery";
  import { invoice } from '@/api/invoice'
  import "@/assets/js/telerikReportViewer-17.0.23.118.min.js";
  import ExcelJS from "exceljs";
  import { saveAs } from 'file-saver-es'
  window.$ = jQuery;
  export default {
    name: "ReportViewer",
    data() {
      return {
        msg: "Print invoice",
        pLang: null,
        pToken: null,
        pTimeZone: null,
        pTimeZoneOffset: null,
        pInvoiceId: null,
        pUrl: null,
        fileName: null,
        listServiceExportExcel: null
      };
    },
    created() {
      this.getInvoiceVAT()
      this.getParam()
    },
    watch: {
      pUrl() {
        setTimeout(() => {
          this.getInvoice()
        }, 500)
      }
    },
    methods: {
      async api_RCOM01() {
        const body = {
          KeyGroup : "REPORT_CONFIG"
        }
        await commonServices.getCommon(body).then(response => {
          this.fileName = response.Data.find(x => x.KeyCode == "RIVAT02_REPORT_FILE_NAME").KeyValue   
        })
      },
      async api_RCF00() {
        await commonServices.api_RCF00().then(response => {
          this.pUrl = `${response.Data.find(x => x.SettingCode == 'SITE_API_URL').SettingValue}/api/course`
        })
      },
      getParam() {
        const userGolfData = JSON.parse(localStorage.getItem('userGolfData'))
        this.pLang =  localStorage.getItem('systemLanguage') || '1000000'
        this.pToken =  (userGolfData) ? userGolfData.AccessToken : ''
        this.pTimeZone =  localStorage.getItem('timeZone')
        this.pTimeZoneOffset =  localStorage.getItem('timeZoneOffset')
        this.pInvoiceId = Number(this.$route.params.id)
        this.api_RCOM01().then(() => {
          this.api_RCF00()
        })
      },
      getInvoice() {
        $("#rivat02").telerik_ReportViewer({
            serviceUrl: REPORT_URL,
            reportSource: {
              report: this.fileName,
              parameters: {pToken: this.pToken,pInvoiceId: this.pInvoiceId,pUrl: this.pUrl,pLang: this.pLang,pTimeZone:this.pTimeZone,pTimeZoneOffset: this.pTimeZoneOffset},
            },
            viewMode: telerikReportViewer.ViewModes.PRINT_PREVIEW,
            scaleMode: telerikReportViewer.ScaleModes.SPECIFIC,
            scale: 1.0,
            sendEmail: { enabled: true },
          });
      },
      async getInvoiceVAT() {
        const body = {
            Invoice : {
                Id: Number(this.$route.params.id)
            }
        }
        await invoice.api_RIVAT02(body).then(res => {
            this.listServiceExportExcel = res.Data.Invoice.Service
            this.listServiceExportExcel.forEach((x,index) => {
                x.STT = index + 1
                x.SerialNo =  res.Data.Invoice.SerialNo + res.Data.Invoice.InvoiceNo
                x.InvoiceNo = res.Data.Invoice.InvoiceNo
                x.InvoiceDate =  this.convertUTC(res.Data.Invoice.InvoiceDate,"DD/MM/YYYY")
                x.RoomNumber = res.Data.Invoice.RoomNumber
                x.MemberName = res.Data.Invoice.MemberName
                x.CompanyName = res.Data.Invoice.CompanyName
                x.Email = res.Data.Invoice.Email
                x.TaxCode = res.Data.Invoice.TaxCode
                x.CompanyAddress = res.Data.Invoice.CompanyAddress
                x.PaymentMethodName = res.Data.Invoice.TransactionCodeName ? res.Data.Invoice.TransactionCodeName : res.Data.Invoice.PaymentMethodName
                x.CheckIn = res.Data.Invoice.CheckIn ? this.convertUTC(res.Data.Invoice.CheckIn,"DD/MM/YYYY") : null
                x.Checkout = res.Data.Invoice.Checkout ? this.convertUTC(res.Data.Invoice.Checkout,"DD/MM/YYYY") : null
                x.ServiceCharge= x.ServiceCharge ?  Math.round(x.ServiceCharge): 0
                x.SpecialTaxAmount = x.SpecialTaxAmount ? Math.round(x.SpecialTaxAmount) : 0
                x.VatValue = x.VatValue * 100 + "%"
                x.UnitPrice = Math.round(x.UnitPrice)
                x.Amount = Math.round(x.Amount)
                x.TaxAmount = Math.round(x.TaxAmount)
            })
        })
      },
      async generateXLSX() {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("My Sheet");
        worksheet.columns = [
            { header: "STT", key: "STT"},
            { header: "BillSeri1", key: "SerialNo"},
            { header: "Vat bill", key: "InvoiceNo"},
            { header: "InvDate", key: "InvoiceDate"},
            { header: "TrnDescription", key: "ServiceName"},
            { header: "Unit", key: "UnitName"},
            { header: "so luong", key: "Quantity"},
            { header: "Don gia", key: "UnitPrice"},
            { header: "thanh tien", key: "Amount"},
            { header: "SV5%", key: "ServiceCharge"},
            { header: "THDB", key: "SpecialTaxAmount"},
            { header: "VAT", key: "VatValue"},
            { header: "Tax10%Amou", key: "TaxAmount"},
            { header: "TotalAmo", key: "TotalAmount"},
            { header: "Room", key: "RoomNumber"},
            { header: "KHACHHANG", key: "MemberName"},
            { header: "COMPANY", key: "CompanyName"},
            { header: "VATCode", key: "TaxCode"},
            { header: "Address1", key: "CompanyAddress"},
            { header: "ModeOfPayment", key: "PaymentMethodName"},
            { header: "Arrdate", key: "CheckIn"},
            { header: "DepDate", key: "Checkout"},
            { header: "email", key: "Email"},
        ];
        this.listServiceExportExcel.forEach( x => {
            worksheet.addRow(x)
        })
  
        workbook.xlsx.writeBuffer().then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `ehoadon_${this.listServiceExportExcel[0].InvoiceDate.slice(0,2)}_${this.listServiceExportExcel[0].InvoiceDate.slice(3,5)}_${this.listServiceExportExcel[0].InvoiceDate.slice(6,10)}_${this.listServiceExportExcel[0].InvoiceNo}.xlsx`)
        })
      },
    },
  };
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style lang="scss" >
  #rivat02 {
    position: absolute;
    top: 50px;
    bottom: 10px;
    left: 0;
    overflow: hidden;
    clear: both;
    width: 90%;
  }
  .print {
    margin: 0 10px 0 10px;
  }
  .left-body {
    width: 10%;
    float: right;
  }.trv-report-page{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  </style>